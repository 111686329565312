import { template as template_760a8610189944fab2fe00ae50156d01 } from "@ember/template-compiler";
const SidebarSectionMessage = template_760a8610189944fab2fe00ae50156d01(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
