import { template as template_89e867f9537b4755988fdcdc4287c37c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_89e867f9537b4755988fdcdc4287c37c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
