import { template as template_ce0f8f1d412749d48bbb6b917cc7a12a } from "@ember/template-compiler";
const FKLabel = template_ce0f8f1d412749d48bbb6b917cc7a12a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
