import { template as template_0f2f0e07e47b4a069f6243a53d9c642d } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SortableColumn from "./sortable-column";
const PostersCell = template_0f2f0e07e47b4a069f6243a53d9c642d(`
  <SortableColumn
    @order="posters"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="posters"
    @screenreaderOnly={{true}}
    aria-label={{i18n "category.sort_options.posters"}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostersCell;
